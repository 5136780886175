/* ─────────────────────────────────────────────
                  기본 설정
───────────────────────────────────────────── */

/* 기본적으로 스크롤바 보이지 않게 하기 */
::-webkit-scrollbar {
    display: none;
}

/* 메인 화면 서버 사진 배경으로 띄우기 */
.background {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    margin: 0;
    position: relative;
}

/* 기본 스타일 초기화 */
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    color: #fff;
}


/* ─────────────────────────────────────────────
                  메인 페이지
───────────────────────────────────────────── */
.background {
    background-color: var(--color-deepgreen);
}

/* 전체 박스 */
.main-wrapper {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    gap: 2em;
}

/* 로고 영역 */
.logo-wrapper {
    color: white;
    text-align: center;
    border-radius: 20px;
    padding-bottom: 1em;
}

/* 로고 */
.logo img { width: 15em; }

/* 메인 타이틀 영역 */
.top-wrapper {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    border-radius: 20px;
}

/* 메인 타이틀 */
.main-title {
    font-weight: bold;
    color: white;
    font-size: 1.7em;
    margin-bottom: 0.3em;
}

/* 메인 타이틀 부제목 영역 */
.main-subtitle {
    color: var(--color-mgray);
    font-size: 1.4em;
    transition: color 0.3s ease;
}

.main-subtitle:hover {
    color: var(--color-lightgreen);
}

/* 서버 정보 전체 영역 */
.server-wrapper {
    display: flex;
    gap: 2.5em;
    margin: 1em;
    border-radius: 20px;
}


/* 서버 정보 표시 영역 */
.server-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1.5em;
    color: white;
    text-align: center;
    border-radius: 20px;
    line-height: 27px;
    box-shadow: var(--shadow-medium);
    background: #00000051;
    -webkit-animation-name: logo;
    animation-name: logo;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-anim1ation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

/* 서버 제목 영역 */
.title {
    font-weight: bold;
    color: white;
    font-size: 1.4em;
    margin-bottom: 5px;
    line-height: 1.2em;
}

/* 서버 부제목 영역 */
.subtitle {
    margin-top: 0.8em;
    color: var(--color-mgray);
    font-size: 1em;
    transition: color 0.3s ease;
}

/* 접속중인 인원수 안내 */
.playercount {
    margin-top: 1.5em;
    font-size: 1.3em;
}

/* 인원수 강조 */
.sip {
    font-weight: bold;
    padding: 1px 4px;
    border-radius: 3px;
    background: #82de89aa;
    margin: 0 2px;
}

/* 메뉴 항목들 wrapper */
.items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 40px;
    gap: 2em;
    margin-top: 1em;
}

/* 
  메뉴 항목들:
  호버시 부제목 슬라이드하며 나타나는 이펙트
*/
.item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
    gap: 5px;
    width: 5.5em;
}

.item span {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 2em;
}

.item span p {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: all 0.2s ease;
    color: white;
}

.item:hover span p {
    transform: translate(-50%, 0%);
}

.item i {
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin-bottom: 7px;
    font-size: 3em;
    color: white;
}

.item i:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

/* ─────────────────────────────────────────────
               에러 페이지
───────────────────────────────────────────── */

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #333;
    gap: 1em;
}

.error-heading {
    font-size: 3.5em;
    font-weight: bold;
    color: var(--color-smoothgreen);
    line-height: 1em;
}

.error-subheading {
    font-size: 1.3em;
    font-weight: 400;
    color: var(--color-smoothgreen);
}

.error-paragraph {
    margin-top: 20px;
    font-size: 1em;
    color: var(--color-lightgreen);
    font-weight: 400;
}

.error-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--color-lightgreen);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;
    font-weight: bold;
}

.error-button:hover {
    background-color: var(--color-lightgreen-highlight);
}

.error-button span {
    color: var(--color-deepgreen);
}

.error-button span i {
    margin-right: 10px;
}

.error-logo-wrap {
    display: flex;
    font-size: 36px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.error-logo-wrapper i {
    font-size: 50px;
    margin-top: 6px;
}

/* ─────────────────────────────────────────────
                정보 화면
───────────────────────────────────────────── */

#wiki {
    width: 100dvw;
    height: 100dvh;
    min-width: 360px;
}

/* 위키 header 스타일 */
.wiki-header {
    padding: 15px;
    background-color: var(--color-deepgreen);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.wiki-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.wiki-header-left .logo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

.wiki-header-left .logo img {
    width: 50px;
    margin: 0;
    padding: 0;
}

.wiki-header-left .wiki-title {
    margin-left: 10px;
    font-size: 1.3em;
    color: var(--color-lightgreen);
    font-weight: bold;
}

.wiki-header-right {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    gap: 20px;
}

.wiki-header-right a {
    font-size: 1em;
    color: var(--color-lightgreen);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.wiki-header-right a.active {
    color: var(--color-lightgreen-highlight);
}

.wiki-header-right a:hover {
    color: var(--color-lightgreen-highlight);
}

.tab-link {
    font-size: 1em;
    background-color: var(--color-deepgreen);
    color: var(--color-lightgreen);
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
}

.tab-link.active {
    color: var(--color-lightgreen-highlight);
}

.tab-link:hover {
    color: var(--color-lightgreen-highlight);
}

.wiki-body {
    margin-top: 50px;
    overflow-x: hidden;
}

/* 트랜지션 */
.tab-content {
    display: none;
}

.tab-content.active {
    display: block;
}

/* 기본 wiki-content 스타일 */
.wiki-content {
    max-width: 1200px;
    padding: 10px 20px 20px 20px;
    margin: 0 auto;
    color: #f0f0f0;
    line-height: 1.6;
    text-align: left;
    opacity: 0;
    transform: translateX(20px);
    animation: fadeInLeft 1s forwards;
}

/* 접속법 건너뛰기 자동 스크롤 마진 조정 */
#mod-install,
#link-discord {
    scroll-margin-top: 150px;
}

/* 헤딩 스타일링 */
.wiki-content h1,
.wiki-content h2,
.wiki-content h3,
.wiki-content h4,
.wiki-content h5,
.wiki-content h6 {
    color: var(--color-lightgreen);
    font-weight: bold;
    margin-bottom: 10px;
}

.wiki-content i {
    margin-right: 10px;
}

.wiki-content h1 {
    font-size: 1.8em;
    margin-bottom: 20px;
    border-bottom: 3px solid var(--color-lightgreen);
    padding-bottom: 10px;
}

.wiki-content h2 {
    font-size: 1.55em;
    margin-top: 20px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--color-lightgreen);
    padding-bottom: 10px;
}

.wiki-content h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
}

.wiki-content h4 {
    font-size: 1.25em;
}

.wiki-content h5 {
    font-size: 1.1em;
}

.wiki-content h6 {
    font-size: 1em;
}

/* 단락 및 인용구 */
.wiki-content p {
    margin-bottom: 10px;
    color: #f0f0f0;
}

.wiki-content a i {
    margin-right: 0;
    margin-left: 5px;
}

.wiki-content blockquote {
    margin: 15px 0;
    padding-left: 15px;
    border-left: 4px solid var(--color-lightgreen);
    color: #d3f4d6;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px 20px;
    font-style: italic;
}

/* 리스트 스타일 */
.wiki-content ul,
.wiki-content ol {
    margin: 10px 0 20px 5px;
    padding-left: 20px;
}

.wiki-content ul {
    list-style-type: disc;
}

.wiki-content ol {
    list-style-type: decimal;
}

.wiki-content ul li,
.wiki-content ol li {
    margin-bottom: 8px;
}

/* 테이블 스타일 */
.wiki-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.wiki-content table th,
.wiki-content table td {
    border: 1px solid var(--color-mgray);
    padding: 10px;
    text-align: left;
    color: #f0f0f0;
}

.wiki-content table th {
    background-color: var(--color-deepgreen);
    color: white;
    font-weight: bold;
}

.wiki-content table td {
    background-color: rgba(255, 255, 255, 0.1);
}

/* 코드 블록 스타일 */
.wiki-content pre {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 10px;
    overflow-x: auto;
    margin-bottom: 20px;
    color: #f0f0f0;
}

.wiki-content code {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2px 5px;
    border-radius: 3px;
    color: #f0f0f0;
}

/* 이미지 스타일 */
.wiki-content img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin: 10px 0;
}

/* 가독성을 위한 추가적인 전역 스타일 */
.wiki-content hr {
    border: 0;
    height: 1px;
    background-color: var(--color-mgray);
    margin: 20px 0;
}

.download::before {
    content: "" !important;
}

#info .wiki-content h6 {
    color: white;
    font-size: 1.2em;
    margin: 20px 0 5px 0;
}

#info .wiki-content ul {
    margin-left: 15px;
}

.download {
    display: inline-block;
    font-weight: bold;
    color: var(--color-lightgreen);
    border: 2px solid var(--color-lightgreen);
    padding: 15px;
    border-radius: 20px;
    margin: 10px 0;
    transition: all 0.3s ease;
}

.download:hover {
    color: var(--color-lightgreen-highlight);
    border: 2px solid var(--color-lightgreen-highlight);
}

/* ─────────────────────────────────────────────
                  모달창
───────────────────────────────────────────── */

.img {
    border-radius: 10px;
}

.modal-trigger {
    cursor: pointer;
}

.modal {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: auto;
    cursor: pointer;
}

.modal-content,
.modal-image {
    border-radius: 10px;
    display: block;
    animation-name: zoom;
    animation-duration: 0.7s;
}

.modal-content {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    line-height: 1.5em;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

/* ─────────────────────────────────────────────
              반응형 미디어쿼리 메인
───────────────────────────────────────────── */

/* 1250px 이상일때 */
@media (min-width: 1200px) {
    body {
        font-size: 22px;
    }

}
/* 900px 이하일때 */
@media (max-width: 900px) {
    body {
        font-size: 16px;
    }

    .wiki-header-wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 1em;
    }

    .wiki-header-right {
        margin: 0;
    }

    /* 태블릿, 모바일 환경에서는 아이콘 설명이 보이게 하기 */
    .item span p {
        transform: translateX(-50%);
    }

}

/* 600px 이하일때 */
@media (max-width: 600px) {
    body {
        font-size: 14px;
    }

    /* 태블릿, 모바일 환경에서는 아이콘 설명이 보이게 하기 */
    .item span p {
        transform: translateX(-50%);
    }
}

/* ─────────────────────────────────────────────
              애니메이션 레퍼런스
───────────────────────────────────────────── */

.fadeInLeft {
    animation: fadeInLeft 0.5s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes logo {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes logo {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
